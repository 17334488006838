<template>
  <c-modal
    class="convenia-modal"
    is-opened
    has-scroll-shadow
    v-bind="$attrs"
    v-on="$listeners"
    no-close
    full-screen
  >
    <template slot="header">
      <c-button
        :class="['back', { '-hidden': noBack }]"
        flat
        icon="chevron-left"
        icon-size="20"
        @click="$emit('back')"
      />
      <c-title v-bind="titleProps" class="title">
        {{ title }}
      </c-title>
    </template>

    <slot />

  </c-modal>
</template>

<script>
export default {
  props: {
    title: String,
    titleProps: Object,
    noBack: Boolean,
    width: Number
  }
}
</script>

<style lang="scss">
.convenia-modal {
  overflow-y: auto;

  &::-webkit-scrollbar-thumb { background-color: rgba(#fff, 0.3) }
}

.convenia-modal > .c-overlay {
  opacity: .9;
  background: linear-gradient(135deg,
    map-get($text-color, base-90),
    map-get($text-color, base));
}

.convenia-modal > .modal {
  &.modal.modal {
    height: 100%;
    border-radius: 0;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255,255,255,0.83) 100%);
  }
  display: flex;
  flex-flow: column nowrap;

  & > .header {
    display: grid;
    grid-template-columns: 40px auto 40px;
    grid-template-areas:
      "back title close";
    position: sticky;
    height: 75px;

    & > .back {
      grid-area: back;

      &.-hidden { visibility: hidden }
    }

    & > .title {
      grid-area: title;
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
      opacity: .8;

      & > .border {
        background: set-linear-gradient(horizontal, (light: var(--color-a), dark:  var(--color-b)));
      }
    }

    & > .actions { grid-area: close }
  }

  & > .wrapper-content > .content { overflow-x: hidden }
  & > .wrapper-content > .content > .title { text-align: center; }

  @include tablet {
    &.modal.modal { max-width: unset }
  }
}
</style>
