<template lang="html">
  <div :class="classes">
    <error-modal
      v-if="isXsMobile || isMobile"
      class="modal"
      is-opened
      @submit="submit"
    />
    <error-desktop v-else class="desktop" @submit="submit" />
  </div>
</template>

<script>
import ErrorModal from '@components/404Error/Modal'
import ErrorDesktop from '@components/404Error/Desktop'

import MediaQuery from '@mixins/MediaQuery'

import * as types from '@store/types'
import { mapGetters } from 'vuex'

export default {
  mixins: [ MediaQuery ],

  components: {
    ErrorModal,
    ErrorDesktop
  },

  computed: {
    ...mapGetters({
      appType: types.SETTINGS_APP_TYPE
    }),

    classes () {
      return [
        'login-error', {
          '-bpo': this.appType === 'bpo'
        }
      ]
    }
  },

  methods: {
    submit () {
      window.history.go(-1)
    }
  }
}
</script>

<style lang="scss">
.login-error {
  &.-bpo {
    --color-a: #{map-get($primary-color-map, light)};
    --color-b: #{map-get($primary-color-map, dark)};
  }
}
</style>
