<template>
  <div class="error-page">
    <c-title
      class="title"
      size="1"
      center
      dark-text
    >
      {{ title }}
    </c-title>

    <img src="../../assets/Error404.svg" class="icon" />

    <p class="info">
      {{ info }}
    </p>

    <c-button
      v-if="buttonMessage"
      class="action"
      type="submit"
      size="60"
      @click="$emit('submit')"
    >
      {{ buttonMessage }}
    </c-button>
  </div>
</template>

<script>
import * as types from '@store/types'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({ LoginTexts: types.SETTINGS_TEXTS }),

    info () { return this.LoginTexts.error404.text },
    title () { return this.LoginTexts.error404.title },
    buttonMessage () { return this.LoginTexts.error404.button }
  }
}
</script>

<style lang="scss">
.error-page {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 70px;

  & > .title {
    margin-top: 10px;

    & > .border{
      background: set-linear-gradient(horizontal, (light: var(--color-a), dark:  var(--color-b)));
    }
  }
  & > .icon {
    height: 446px;
    padding-right: 55px;
  }
  & > .info { margin-top: 8px; }
  & > .action {
    width: 240px;
    margin-top: 40px;
    background: set-linear-gradient(135deg, (light: var(--color-a), dark:  var(--color-b)));
    & > .border { border-color: var(--color-a) }
    &::before {background: set-linear-gradient(135deg, (light: var(--color-a), dark:  var(--color-b))) }
  }
}
</style>
