<template lang="html">
  <convenia-modal
    class="error-modal"
    no-back
    no-header
    no-title
    no-close
    v-bind="$attrs"
    v-on="$listeners"
  >
    <c-title
      class="title"
      size="1"
      dark-text
      center
    >
      {{ title }}
    </c-title>

    <img src="../../assets/Error404.svg" class="icon" />

    <p class="info">
      {{ info }}
    </p>

    <c-button
      v-if="buttonMessage"
      class="action"
      type="submit"
      size="40"
      @click="$emit('submit')"
    >
      {{ buttonMessage }}
    </c-button>
  </convenia-modal>
</template>

<script>
import ConveniaModal from '@components/common/Modal'

import * as types from '@store/types'
import { mapGetters } from 'vuex'

export default {
  components: { ConveniaModal },

  computed: {
    ...mapGetters({ LoginTexts: types.SETTINGS_TEXTS }),

    info () { return this.LoginTexts.error404.text },
    title () { return this.LoginTexts.error404.title },
    buttonMessage () { return this.LoginTexts.error404.button }
  }
}
</script>

<style lang="scss">
.error-modal {
  & > .modal > .wrapper-content > .content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding-top: 45px;
    overflow-y: auto;

    & > .title > .border {
      background: set-linear-gradient(horizontal, (light: var(--color-a), dark:  var(--color-b)));
    }

    & > .icon {
      max-height: 223px;
      padding-top: 30px;
      padding-right: 7px;
      align-self: flex-start;
    }

    & > .info {
      width: 280px;
      margin-top: 30px;
      font-size: 16px;
      text-align: center;
    }

    & > .action {
      width: 150px;
      margin-top: 20px;
      background: set-linear-gradient(135deg, (light: var(--color-a), dark:  var(--color-b)));
      & > .border { border-color: var(--color-a) }
      &::before {background: set-linear-gradient(135deg, (light: var(--color-a), dark:  var(--color-b))) }
    }

    @include mobile {
      & > .icon {
        margin-top: 0;
        max-height: none;
        min-height: 250px;
      }
    }
  }

}
</style>
